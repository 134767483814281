<template>
  <div class="container">
    <div class="header">
      <div class="left">
        <img alt="Merethe Profil" src="./assets/img/meretheProfil.jpeg" />
      </div>
      <div class="right">
        <h1>Sandefjord Proteselaboratorium AS</h1>
        <div class="content">
          <p>
            Som autorisert tanntekniker med over 30 års erfaring i faget,
            offentlig godkjent og medlem av Norges Tannteknikerforbund, er jeg
            en trygg samarbeidspartner.
          </p>
          <ul>
            <li>
              <b>Merethe Skjold</b>
            </li>
            <li>Aut. Tanntekniker / Daglig leder</li>
          </ul>
          <div class="contact">
            <div>
              <BIconTelephone />
              <span>
                <a href="tel:+4741463713">414 63 713</a>
              </span>
            </div>
            <div>
              <BIconEnvelope />
              <span>
                <a href="mailto: merethe.skjold@sfjbb.net">{{ mailto }}</a>
              </span>
            </div>
            <div>
              <BIconPinMap />
              <span>
                <a
                  target="_blank"
                  href="https://www.google.com/maps/place/Dronningens+gate+3,+3211+Sandefjord/@59.130663,10.2211451,17z/data=!3m1!4b1!4m5!3m4!1s0x4646c090fde3167d:0x4743484e0df31501!8m2!3d59.130663!4d10.2211451"
                >
                  {{ address }}
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="round right">
      <div>
        <p>
          Sandefjord Proteselaboratorium AS er et firma som har spesialisert seg
          på produksjon av avtagbar protetikk - nye tannproteser i akryl og
          akryl/cobolt-crom samt reparasjon og vedlikehold av brukte gebiss.
        </p>
      </div>

      <img alt="Arbeid med protese" src="./assets/img/arbeidMedProtese.jpeg" />
    </div>
    <div class="round left">
      <img alt="Arbeid med protese" src="./assets/img/meretheArbeider.jpeg" />
      <div>
        <p>
          All produksjon foregår i mitt eget laboratorium og leveringstiden blir
          derfor kort.
        </p>
        <p>Det meste av reparasjoner og vedlikehold kan gjøres på dagen.</p>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BIconEnvelope,
  BIconPinMap,
  BIconTelephone,
} from "bootstrap-icons-vue";

export default {
  name: "App",
  components: {
    BIconTelephone,
    BIconEnvelope,
    BIconPinMap,
  },
  data() {
    return {
      mailto: "merethe.skjold@sfjbb.net",
      address: "Dronningensgate 3, 3211 Sandefjord",
    };
  },
  beforeCreate() {
    document.title = "Sandefjord Proteselaboratorium";
  },
  beforeMount() {
    let playfair = document.createElement("link");
    playfair.setAttribute("rel", "stylesheet");
    playfair.setAttribute(
      "href",
      "https://fonts.googleapis.com/css2?family=Playfair+Display"
    );
    document.head.appendChild(playfair);

    let lato = document.createElement("link");
    lato.setAttribute("rel", "stylesheet");
    lato.setAttribute("href", "https://fonts.googleapis.com/css2?family=Lato");
    document.head.appendChild(lato);

    let meta = document.createElement("meta");
    meta.setAttribute("name", "viewport");
    meta.setAttribute("content", "width=device-width");
    document.head.appendChild(meta);
  },
};
</script>

<style lang="scss"></style>
